import React, { useEffect } from "react";
import {
  BootcampPolicyQAContainer,
  HeroSection,
  Section,
  Heading,
  Paragraph,
  List,
  ListItem,
  ButtonLink,
} from "./style.js";

const BootcampPolicyQA = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <BootcampPolicyQAContainer>
      {/* Hero Section */}
      <HeroSection>
        <div className="hero-content">
          <Heading>
            Skills Bootcamps - <span>Privacy Data Q&A</span>
          </Heading>
          <Paragraph>
            Learn why your personal data is collected, how it is processed, and
            what happens to it afterward.
          </Paragraph>
        </div>
      </HeroSection>

      {/* Main Content Sections */}
      <Section>
        <Heading>Why Do You Need My Personal Data?</Heading>
        <Paragraph>
          We collect personal data on applicants and participants to help
          effectively administer the programme with the training providers and
          to support the evaluation of the programme. Only information
          necessary for these purposes is collected.
        </Paragraph>
      </Section>

      <Section>
        <Heading>What Will You Be Doing With My Personal Data?</Heading>
        <Paragraph>
          We will collect your personal data and link it to administrative data
          the government holds on tax and benefits to evaluate the impact of
          Skills Bootcamps on earnings and employment over several years. This
          ensures the programme is effective, provides value for money, and
          improves service provision.
        </Paragraph>
        <Paragraph>
          Identifying details will be removed in the process, and no individual
          details will be made public. The evaluation is conducted on the
          overall programme. You may also be contacted to participate in
          surveys or interviews about your experience with the programme.
          Participation is voluntary.
        </Paragraph>
      </Section>

      <Section>
        <Heading>
          What Other Government Data Will Be Linked to My Personal Data?
        </Heading>
        <Paragraph>
          We will link your data to the following government information to
          evaluate the impact of Skills Bootcamps:
        </Paragraph>
        <List>
          <ListItem>HMRC P45, P46, P14, and Self-Assessment data</ListItem>
          <ListItem>HMRC Pay-as-you-earn Real Time Information</ListItem>
          <ListItem>DWP National Benefit Database and Labour Market System</ListItem>
          <ListItem>DWP Universal Credit data</ListItem>
          <ListItem>
            Individual Learner Record (“ILR”), Higher Education Statistics
            Agency (“HESA”), and Student Loans Company (“SLC”) data
          </ListItem>
          <ListItem>
            Higher Education Funding Council for England (HEFCE) data collected
            by HESA
          </ListItem>
          <ListItem>DfE National Pupil Database (“NPD”)</ListItem>
        </List>
      </Section>

      <Section>
        <Heading>
          Why Are You Collecting Information on Protected Characteristics?
        </Heading>
        <Paragraph>
          Information on protected characteristics, such as ethnicity, is
          collected to monitor how well the policy supports equality and
          diversity. Providing this information is optional, with a ‘Prefer Not
          to Say’ option.
        </Paragraph>
      </Section>

      <Section>
        <Heading>What Happens With My Information Afterwards?</Heading>
        <Paragraph>
          Your information is used solely for the purposes specified. Personal
          information is retained for a maximum of three years, after which it
          will be securely destroyed. A pseudo-anonymised version, used for
          research purposes, will be kept for up to 20 years.
        </Paragraph>
        <Paragraph>
          If you wish to request the removal of your personal information from
          our database, please use the following link:
        </Paragraph>
        <a
          href="https://form.education.gov.uk/service/Contact_the_Department_for_Education"
          target="_blank"
          rel="noreferrer"
        >
          <ButtonLink>Request Data Removal</ButtonLink>
        </a>
      </Section>
    </BootcampPolicyQAContainer>
  );
};

export default BootcampPolicyQA;
import React, { useEffect } from "react";
import {
  BootcampPolicyContainer,
  HeroSection,
  Section,
  Heading,
  Paragraph,
  List,
  ListItem,
  ButtonLink,
} from "./style.js";
import { Link } from "react-router-dom";

const BootcampPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <BootcampPolicyContainer>
      {/* Hero Section */}
      <HeroSection>
        <div className="hero-content">
          <Heading>
            Skills Bootcamps - <span>Privacy Notice</span>
          </Heading>
          <Paragraph>
            Learn how we collect, store, and use your personal information for
            evaluating our programs and improving your experience.
          </Paragraph>
          <Link to="/contact">
            <ButtonLink>Contact Us</ButtonLink>
          </Link>
        </div>
      </HeroSection>

      {/* Main Content Sections */}
      <Section>
        <Heading>Who We Are</Heading>
        <Paragraph>
          This privacy notice explains how we collect, store and use your personal information for
          the purposes of evaluating the Skills Bootcamps programme.
        </Paragraph>
        <Paragraph>
          For the purposes of relevant data protection legislation, the Department for Education
          (DfE) is the data controller for personal information processed for this research. More
          information about how the DfE handles personal information is published here:{" "}
          <a
            href="https://www.gov.uk/government/organisations/department-for-education/about/personal-information-charter"
            target="_blank"
            rel="noreferrer"
          >
            personal information charter
          </a>
          .
        </Paragraph>
      </Section>

      <Section>
        <Heading>Why We Collect Your Information</Heading>
        <Paragraph>
          We are collecting data on Skills Bootcamp applicants, and participants to help the DfE
          understand how well the courses are working, investigating individuals’ concerns
          regarding their course and if individuals are achieving their outcomes. This is important
          because it allows us to be transparent about how government spends public money and
          measures the impact that policies are having, as well as helping us make improvements
          to future training courses. This data also helps us check if the people who are on the
          course do complete the course and ensures that the correct amount of funding is paid.
        </Paragraph>
      </Section>

      <Section>
        <Heading>The nature of your personal data we will be using</Heading>
        <Paragraph>
          As part of the data collection we are asking for your personal data;
        </Paragraph>
        <List>
          <ListItem>National Insurance Number</ListItem>
          <ListItem>First Name</ListItem>
          <ListItem>Surname</ListItem>
          <ListItem>Postcode and</ListItem>
          <ListItem>Date of Birth</ListItem>
          <ListItem>Education and Qualifications information</ListItem>
          <ListItem>Earnings and Employment Information</ListItem>
          <ListItem>Benefits Information</ListItem>
          <ListItem>Caring Responsibilities</ListItem>
        </List>

        <Paragraph>The special category data we will be processing includes:</Paragraph>
        <List>
          <ListItem>Gender</ListItem>
          <ListItem>Disability and</ListItem>
          <ListItem>Ethnicity</ListItem>
        </List>

        <Paragraph>
          As part of our work to evaluate the effectiveness of Skills Bootcamps in supporting
          people to gain employment and higher incomes we will link this data to records on
          education and training, income, employment and benefits which are held by the
          Department for Education, the Department of Work and Pensions and Her Majesty’s
          revenue and customs. This is to evaluate the programme’s overall impact. For more
          information on this, please look at our Privacy Notice Q&A.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Our Legal Basis for Collecting Your Personal Information</Heading>
        <Paragraph>
          We collect personal information only where necessary and where the law permits. To
          ensure the use of your data is lawful, we meet specific conditions outlined in data
          protection legislation.
        </Paragraph>
        <List>
          <ListItem>
            <strong>Article 6 (1)(e):</strong> It is necessary to collect data from users to
            effectively access the service and evaluate outcomes of the programme. The
            legal basis is public task, and the legal gateway is Section 87 of the Education
            and Skills Act 2008.
          </ListItem>
          <ListItem>
            <strong>Article 6 (1)(f):</strong> It is necessary to collect data from users as the
            processing is essential for the organisation's legitimate interests or those of a
            third party unless these are overridden by the individual's data protection rights.
          </ListItem>
          <ListItem>
            <strong>Article 9(2)(g):</strong> For special category data processing, this ensures
            equality of opportunity or treatment, as outlined in Schedule 1, Part 2, paragraph
            8 of the Data Protection Act 2018.
          </ListItem>
        </List>
        <Paragraph>
          We may request your participation in interviews and surveys as part of the
          programme evaluation. This could include checks to ensure correct funding amounts
          and gathering feedback on the Skills Bootcamp. Your participation is voluntary.
        </Paragraph>
      </Section>

      <Section>
        <Heading>How we use your personal information</Heading>
        <Paragraph>
          Personal information collected is treated as confidential and collected for research
          purposes, course quality assurance purposes, contract management assurance purposes, 
          and to prevent the risk of fraud. Any information shared publicly will be anonymised so 
          you cannot be identified.
        </Paragraph>
        <Paragraph>
          DfE (and its contracted research organisation) will use the data for policy development 
          and to help improve education services. We may publish the findings for use by other 
          relevant organisations and for the purposes of transparency in how we are using public 
          funds. None of your individualised data will be identified.
        </Paragraph>
      </Section>

      <Section>
        <Heading>How long we will keep your personal data</Heading>
        <Paragraph>
          We will keep your personal data in its original format for a maximum of 3 years, after
          which point it will be securely destroyed. A pseudonymised version of your personal
          data to be used for research purposes will be kept for a maximum of 20 years. We will
          conduct reviews every 5 years to test if it is necessary to still retain this data. For
          qualitative interviews, DfE (or its contracted research supplier) will review the notes,
          recordings and other research data after the session. Voice recordings will be deleted
          by the research contractor as soon as they are transcribed and no later than one year
          after the interview has taken place.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Who we will make your personal data available to</Heading>
        <Paragraph>
          We sometimes need to make personal data available to other organisations. These
          include contracted partners whom we may employ to process your personal data on
          our behalf and/or other organisations (with whom we need to share your personal data
          for specific purposes). We plan to track participants’ longer-term outcomes through
          links to administrative data held by DfE, DWP, and HMRC.
        </Paragraph>
        <Paragraph>
          Where we need to share your personal data with others, we ensure that this sharing
          complies with data protection legislation. For the purposes of this project, we need to
          share your personal data with external evaluators who will:
        </Paragraph>
        <List>
          <ListItem>
            Analyse your personal data on behalf of DfE to evaluate providers of Skills Bootcamps
            and participant outcomes, to contribute to improving the next wave of provision.
          </ListItem>
          <ListItem>
            Follow up with you directly to invite you to take part in qualitative interviews or a
            survey to understand your experience of participating in the Skills Bootcamps.
            Participation in surveys and/or interviews is voluntary, and you can opt out by
            requesting this from our third-party contractor.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Heading>Request to access, rectify or erase your information</Heading>
        <Paragraph>
          This service is optional for individuals to use. As part of the public task and legitimate
          interest purposes, we collect personal information and use this to link to government
          administrative records on income, employment, and benefits. If participants decide they
          do not want their data used on an ongoing basis for research, they can notify the
          department and withdraw from the training. No further data will be collected/linked on
          that individual beyond that point.
        </Paragraph>
        <Paragraph>
          Special category data collection will include a ‘prefer not to say’ option, giving individuals
          the free will to choose whether to provide it or not.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Your data protection rights</Heading>
        <List>
          <ListItem>
            <strong>The right to access:</strong> You are entitled to ask if we hold information
            relating to you and ask for a copy by making a "subject access request."
          </ListItem>
          <ListItem>
            <strong>The right to rectification:</strong> You have the right to request to correct
            any information you believe is inaccurate.
          </ListItem>
          <ListItem>
            <strong>The right to erasure:</strong> You have the right to request for your information
            to be erased under certain circumstances.
          </ListItem>
          <ListItem>
            <strong>The right to restrict processing:</strong> You have the right to request that DfE
            restricts the processing of your personal data under certain circumstances.
          </ListItem>
          <ListItem>
            <strong>The right to object to processing:</strong> You have the right to object to DfE’s
            processing under certain circumstances.
          </ListItem>
          <ListItem>
            <strong>The right to data portability:</strong> You have the right to move your personal
            data to another data controller.
          </ListItem>
        </List>
        <Paragraph>
          For further information and how to request your data, please use the {" "}
          <a
            href="https://form.education.gov.uk/service/Contact_the_Department_for_Education"
            target="_blank"
            rel="noreferrer"
          >
            contact form
          </a>{" "} in
          the Personal Information Charter at:{" "}
          <a
            href="https://www.gov.uk/government/organisations/department-for-education/about/personal-information-charter"
            target="_blank"
            rel="noreferrer"
          >
            https://www.gov.uk/government/organisations/department-for-education/about/personal-information-charter
          </a>{" "} under ‘How to find out what personal information we hold about you’
          section.
        </Paragraph>
      </Section>

      <Section>
        <Heading>Further Information</Heading>
        <Paragraph>
          You can also find more information from the Information Commissioner’s Office at{" "}
          <a
            href="https://ico.org.uk/for-organisations/guide-to-data-protection/"
            target="_blank"
            rel="noreferrer"
          >
            ICO Guide to Data Protection
          </a>
          .
        </Paragraph>
        <Paragraph>
          For queries about this Privacy Notice or how DfE handles your personal information,
          contact our Data Protection Officer via the{" "}
          <a
            href="https://form.education.gov.uk/service/Contact_the_Department_for_Education"
            target="_blank"
            rel="noreferrer"
          >
            contact form
          </a>{" "}
          or write to:
        </Paragraph>
        <Paragraph>
          Department for Education
          <br />
          Sanctuary Buildings, Great Smith Street
          <br />
          LONDON, SW1P 3BT
        </Paragraph>
      </Section>
    </BootcampPolicyContainer>
  );
};

export default BootcampPolicy;

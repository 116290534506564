import styled from "styled-components";

export const WistleblowingPolicyContainer = styled.div`
  background-color: #fffcf4;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 10rem 0;
`;

export const HeroSection = styled.div`
  background-color: #f4f4f4;
  padding: 4rem 2rem;
  text-align: center;

  .hero-content {
    max-width: 800px;
    margin: auto;
  }
`;
export const Section = styled.div`
  padding: 3rem 2rem;
  max-width: 800px;
  margin: auto;
`;

export const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #fbaf00;

  span {
    color: #000;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 2rem 0;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 1rem;
  background-color: #fbaf00;
  color: #fff;
  font-weight: bold;
  width: 30%;
`;

export const TableCell = styled.td`
  text-align: left;
  padding: 1rem;
  vertical-align: top;

  &:last-child {
    text-align: center;
  }
`;

export const SubRow = styled.div`
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 0.9rem;
`;

export const ExternalLink = styled.a`
  color: #fbaf00;
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;
export default WistleblowingPolicyContainer;
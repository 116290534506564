import React, { useEffect } from "react";
import {
  WistleblowingPolicyContainer,
  HeroSection,
  Section,
  Heading,
  Paragraph,
  ExternalLink,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  SubRow,
} from "./style.js";

const WistleblowingPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <WistleblowingPolicyContainer>
      {/* Hero Section */}
      <HeroSection>
        <div className="hero-content">
          <Heading>
            Skills Bootcamps - <span>Whistleblowing and Complaints Policy</span>
          </Heading>
          <Paragraph>
            Your support experience and satisfaction are of utmost
            priority for us here at Niyo; however, if you are displeased, do
            well to follow the below escalation matrix at any level and
            time of the incident, whether present, past, anticipated or
            at a feeling of unresolved complaints.
          </Paragraph>
        </div>
      </HeroSection>

      {/* Main Content Sections */}
      <Section>
        <Heading>Escalation Matrix</Heading>
        <Table>
          {/* First Level of Contact */}
          <TableRow>
            <TableHeader>1st Level of Contact</TableHeader>
            <TableCell>data@niyo.co</TableCell>
            <TableCell>+44 772 628 6348</TableCell>
          </TableRow>

          {/* Next Level of Contacts */}
          <TableRow>
            <TableHeader>Next Level of Contacts</TableHeader>
            <TableCell colSpan="2">
              <SubRow>Department for Education’s whistleblowing helplines</SubRow>
              <SubRow>
                Complaints procedure -{" "}
                <ExternalLink
                  href="https://www.gov.uk/government/organisations/department-for-education/about/complaints-procedure"
                  target="_blank"
                  rel="noreferrer"
                >
                  GOV.UK
                </ExternalLink>
              </SubRow>
              <SubRow>
                Whistleblowing Policy -{" "}
                <ExternalLink
                  href="https://www.wmca.org.uk/policies/policies/policies/whistleblowing-and-fraud/"
                  target="_blank"
                  rel="noreferrer"
                >
                  WMCA Whistleblowing and Fraud Policy
                </ExternalLink>
              </SubRow>
              <SubRow>
                Contact the Department for Education (DfE) -{" "}
                <ExternalLink
                  href="https://www.gov.uk/contact-dfe"
                  target="_blank"
                  rel="noreferrer"
                >
                  GOV.UK Contact DfE
                </ExternalLink>
              </SubRow>
            </TableCell>
          </TableRow>
        </Table>
      </Section>
    </WistleblowingPolicyContainer>
  );
};

export default WistleblowingPolicy;
import styled from "styled-components";

export const BootcampPolicyQAContainer = styled.div`
  background-color: #fffcf4;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 10rem 0;
`;

export const HeroSection = styled.div`
  background-color: #f4f4f4;
  padding: 4rem 2rem;
  text-align: center;

  .hero-content {
    max-width: 800px;
    margin: auto;
  }
`;

export const Section = styled.div`
  padding: 3rem 2rem;
  max-width: 800px;
  margin: auto;
`;

export const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #fbaf00;

  span {
    color: #000;
  }
`;


export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #333;

  a {
    color: #fbaf00;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }
`;

export const List = styled.ul`
  padding-left: 1.5rem;
  margin-bottom: 2rem;
`;

export const ListItem = styled.li`
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
`;

export const ButtonLink = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #fbaf00;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #e19a00;
  }
`;

export default BootcampPolicyQAContainer;
import "./App.css";
import "react-notifications/lib/notifications.css";

import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Partners from "./pages/Partner";
import OurStory from "./pages/OurStory";
import PartnersForm from "./pages/Partner/form";
import Bootcamps from "./pages/Bootcamps";
import Web2Page from "./pages/Bootcamps/web2";
import Web3Page from "./pages/Bootcamps/web3";
import HairPage from "./pages/Bootcamps/hair";
import FoundhersPage from "./pages/Bootcamps/foundhers";
import FashionPage from "./pages/Bootcamps/fashion";
import RegistrationForm from "./pages/Registration";
import FoundhersCourse from "./pages/IndividualBootcamps/foundhers";
import SoftwareCourse from "./pages/IndividualBootcamps/software";
import DataCourse from "./pages/IndividualBootcamps/data";
import HairCourse from "./pages/IndividualBootcamps/hair";
import VRCourse from "./pages/IndividualBootcamps/vr";
import PostJob from "./pages/Jobs/postJob";
import Details from "./pages/Jobs/details";
import Payment from "./pages/Jobs/payment";
import Success from "./pages/Jobs/success";
import FashionCourse from "./pages/IndividualBootcamps/fashion";
import SoftwareTestingCourse from "./pages/IndividualBootcamps/softwareTesting";
import MarketingCourse from "./pages/IndividualBootcamps/marketing";
import WebDev from "./pages/IndividualBootcamps/webDev";
import Hotjar from '@hotjar/browser'
import Entrepreneurship from "./pages/IndividualBootcamps/entrepreneurship";
import BootcampPolicy from "./pages/Legals/BootcampPolicy";
import BootcampPolicyQA from "./pages/Legals/BootcampPolicyQA";
import WistleblowingPolicy from "./pages/Legals/WistleblowingPolicy";


const siteId = 3740399;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// Initializing with `debug` option:
Hotjar.init(siteId, hotjarVersion, {
  debug: true
});
function App() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [chosenPackage, setChosenPackage] = useState({});  
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/our-story" element={<OurStory />}></Route>
          <Route exact path="/jobs" element={<Jobs />}></Route>
          <Route exact path="/partners" element={<Partners />}></Route>
          <Route exact path="/partners-form" element={<PartnersForm />}></Route>
          <Route exact path="/bootcamps" element={<Bootcamps />}></Route>
          <Route exact path="/web2-bootcamps" element={<Web2Page />}></Route>
          <Route exact path="/web3-bootcamps" element={<Web3Page />}></Route>
          <Route exact path="/hair-bootcamps" element={<HairPage />}></Route>

          <Route
            exact
            path="/foundher-bootcamps"
            element={<FoundhersPage />}
          ></Route>

          <Route
            exact
            path="/fashion-bootcamps"
            element={<FashionPage />}
          ></Route>

          <Route
            exact
            path="/registration/:id"
            element={<RegistrationForm />}
          ></Route>

          <Route
            exact
            path="/foundher/:id"
            element={<FoundhersCourse />}
          ></Route>

          <Route
            exact
            path="/software-course/:id"
            element={<SoftwareCourse />}
          ></Route>

<Route
            exact
            path="/entrepreneur/:id"
            element={<Entrepreneurship />}
          ></Route>


<Route
            exact
            path="/marketing/:id"
            element={<MarketingCourse />}
          ></Route>

          <Route exact path="/data-course/:id" element={<DataCourse />}></Route>

          <Route
            exact
            path="/hair-beauty-course/:id"
            element={<HairCourse />}
          ></Route>

          <Route
            exact
            path="/fashion/:id"
            element={<FashionCourse />}
          ></Route>

          <Route exact path="/vr-coursee/:id" element={<VRCourse />}></Route>
          <Route exact path="/software-dev/:id" element={<WebDev />}></Route>

          <Route
            exact
            path="/testing/:id"
            element={<SoftwareTestingCourse />}
          ></Route>

          <Route
            exact
            path="/jobs/post-job"
            element={
              <PostJob
                chosenPackage={chosenPackage}
                setChosenPackage={setChosenPackage}
              />
            }
          ></Route>

          <Route
            exact
            path="/jobs/details"
            element={
              <Details
                chosenPackage={chosenPackage}
                setChosenPackage={setChosenPackage}
              />
            }
          ></Route>
          <Route
            exact
            path="/jobs/payment"
            element={
              <Payment
                chosenPackage={chosenPackage}
                setChosenPackage={setChosenPackage}
              />
            }
          ></Route>
          <Route
            exact
            path="/jobs/success"
            element={
              <Success
                chosenPackage={chosenPackage}
                setChosenPackage={setChosenPackage}
              />
            }
          ></Route>
          {/* <Route exact path="*" element={<NotFound />}></Route> */}
          <Route exact path="/policy/bootcamp-policy" element={<BootcampPolicy />}></Route>
          <Route exact path="/policy/bootcamp-policy-qa" element={<BootcampPolicyQA />}></Route>
          <Route exact path="/policy/wistleblowing-policy" element={<WistleblowingPolicy />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
